:root {
  --peek: 8rem;
  --padding: 1.5rem;
  --line-height: 4.8rem;
  --transition-duration: .3s;
}

@media screen and (width <= 767px) {
  :root {
    --peek: 4.5rem;
    --padding: 1.1rem;
    --line-height: 2.8rem;
  }
}

@font-face {
  font-family: SangBleu Kingdom Light;
  src: url("SangBleuKingdom-Light-WebS.7850a4ea.eot");
  src: url("SangBleuKingdom-Light-WebS.7850a4ea.eot#iefix") format("embedded-opentype"), url("SangBleuKingdom-Light-WebS.100ee3c6.woff2") format("woff2"), url("SangBleuKingdom-Light-WebS.a554a035.woff") format("woff"), url("SangBleuKingdom-Light-WebS.56860046.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SuisseIntl Light S;
  src: url("SuisseIntl-Light-WebS.91ede628.eot");
  src: url("SuisseIntl-Light-WebS.91ede628.eot#iefix") format("embedded-opentype"), url("SuisseIntl-Light-WebS.5eff9ba9.woff2") format("woff2"), url("SuisseIntl-Light-WebS.a0f85e33.woff") format("woff"), url("SuisseIntl-Light-WebS.9134c771.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

html, body {
  background: #000;
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
}

html {
  font-size: 62.5%;
}

body {
  color: #fff;
  white-space: nowrap;
  font-size: 6rem;
}

.split-half--right {
  text-transform: uppercase;
  font-family: SangBleu Kingdom Light, serif;
}

.split-half--left {
  text-transform: uppercase;
  font-family: SuisseIntl Light S, sans-serif;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
  font-size: unset;
  line-height: unset;
  text-align: center;
  margin: 0;
  padding: 0;
}

a, a:visited, a:active, a:focus {
  color: inherit;
  text-decoration: none;
}

.split {
  height: 100%;
}

.split-half {
  transition: all var(--transition-duration) ease-out;
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

@media screen and (width >= 768px) {
  .split-half__section-title {
    margin-top: .8rem;
  }
}

@media screen and (width <= 767px) {
  .split-half__section-title {
    margin-top: .6rem;
  }
}

.split-half__section-title .subtitle {
  display: none;
}

.split-half svg {
  display: block;
}

@media screen and (width >= 768px) {
  .split-half svg {
    height: var(--line-height);
  }
}

@media screen and (width <= 767px) {
  .split-half svg {
    height: var(--line-height);
  }
}

.split-half__inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
}

.split-half.full-width {
  cursor: default;
}

.split-half--left {
  left: 0;
  right: 50%;
}

.split-half--left .split-half__media {
  filter: brightness(93%);
  padding-right: var(--peek);
}

.split-half--left .split-half__inner {
  left: 0;
}

.split-half--left.full-width {
  right: var(--peek);
}

.split-half--left.full-width.shrink {
  right: calc(var(--peek) * 1.25);
}

.split-half--right {
  left: 50%;
  right: 0;
}

.split-half--right .split-half__inner {
  right: 0;
}

.split-half--right .split-half__media {
  padding-left: var(--peek);
}

.split-half--right.full-width {
  left: var(--peek);
}

.split-half--right.full-width.shrink {
  left: calc(var(--peek) * 1.25);
}

.split-half.front {
  z-index: 1;
}

.split-half__text, .split-half__contact {
  z-index: 1;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

.split-half__text {
  top: var(--padding);
}

.split-half__media {
  width: 100vw;
  height: 100%;
}

.split-half__media__item {
  background: #000;
  width: calc(100% + 4px);
  height: 100%;
  display: none;
  position: relative;
  left: -2px;
}

@media screen and (width >= 768px) {
  .split-half__media__item--image .inner.mobile {
    display: none;
  }
}

@media screen and (width <= 767px) {
  .split-half__media__item--image .inner.desktop {
    display: none;
  }
}

.split-half__media__item--image .inner, .split-half__media__item--image .image {
  width: 100%;
  height: 100%;
}

.split-half__media__item--image .image {
  background-position: center;
  background-repeat: repeat;
  background-size: auto 100%;
}

.split-half__media__item--image.cover .image {
  background-size: cover;
}

.split-half__media__item--video {
  object-fit: cover;
}

.split-half__media__item--active {
  display: block;
}

.split-half__contact {
  bottom: var(--padding);
}

.video {
  aspect-ratio: 1.77778;
  pointer-events: none;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.video iframe {
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.credits {
  text-transform: uppercase;
  z-index: 2;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  letter-spacing: .15625rem;
  height: var(--peek);
  left: 100%;
  bottom: calc(var(--padding)  + .1 * var(--line-height));
  align-items: center;
  font-family: SangBleu Kingdom Light, serif;
  font-size: .9rem;
  line-height: 1.3;
  display: flex;
  position: fixed;
  -ms-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

@media screen and (width <= 767px) {
  .credits {
    letter-spacing: .125rem;
  }
}

.credits__credit {
  margin-right: 1.5rem;
  display: inline-block;
}

@media screen and (width <= 767px) {
  .credits__credit {
    display: block;
  }
}
/*# sourceMappingURL=index.fe6fe61f.css.map */
